/* eslint-disable */

import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
// import vuetify from './plugins/vuetify'
// import api from '@/plugins/api'
import router from './router'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/fonts/ptsans/stylesheet.css'
import "vue-select/dist/vue-select.css"
import upperFirst from 'lodash-es/upperFirst'
import camelCase from 'lodash-es/camelCase'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Notifications from 'vue-notification'
// import moment from 'moment'
import moment from 'moment-timezone'
import vSelect from 'vue-select'
import i18n from './i18n'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faVk, faFacebookF, faTwitter, faTelegram, faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import visibility from 'vue-visibility-change';
// import { IconPack, IconLookup } from "@fortawesome/fontawesome-common-types";

import VuePerfectScrollbar from 'vue2-perfect-scrollbar';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
Vue.use(VuePerfectScrollbar);

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})

import Skeleton from 'vue-loading-skeleton';
Vue.use(Skeleton)

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

import YmapPlugin from 'vue-yandex-maps'
Vue.use(YmapPlugin, {
    apiKey: '2d3b7b8f-9cde-4f86-8893-1d890c6902f8',
    lang: 'ru_RU',
    coordorder: 'latlong',
    version: '2.1'
})

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(visibility);

library.add(faInstagram)
library.add(faVk)
library.add(faFacebookF)
library.add(faTelegramPlane)
library.add(faTelegram)
library.add(faTwitter)
library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component("v-select", vSelect)

/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/

Vue.use(Notifications)
Vue.config.productionTip = false
Vue.prototype["$env"] = window["__env"];

import VueMask from 'v-mask'
import Util from "./util";
Vue.use(VueMask)

// let VueMask = require('v-mask');
// Vue.use(VueMask);

// declare global {
//   interface Window {
//       __env: any;
//   }
// }

function payUrl(guid:string) {
    let pay_url:any = window["__env"].checkout_url;
    if (guid) {
        return pay_url.replace("{guid}", guid);
    }
}
Vue.filter('payUrl', payUrl)

Vue.filter('formatTextLink', function(value:any) {
    let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return value.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
})

Vue.filter('formatNameLink', function(value:any) {
    let tiktokRegex = /\@\w+/g; // TikTok
    return value.replace(tiktokRegex, function(url) {
        return '<a target="_blank" href="https://www.tiktok.com/' + url + '">' + url + '</a>';
    });
})

Vue.filter('formatDate', function(value:any) {
    if (value) {
        if (typeof(value) == 'number') {
            return moment.unix(value).format('DD.MM.YYYY')
        }
        else {
            return moment(value).format('DD.MM.YYYY')
        }
    }
})
Vue.filter('formatDateTime', function(value:any) {
  if (value) {
      if (typeof(value) == 'number') {
          return moment.unix(value).format('HH:mm')
      }
      else {
          return moment.unix(value).format('HH:mm')
      }
  }
})
Vue.filter('formatDateInterval', function(_:any, tsFrom:number, tsTo:number, timezone:string) {
  if (tsFrom > 0 && tsTo > 0) {
    const mTsFrom = moment.unix(tsFrom);
    const mTsTo = moment.unix(tsTo);
    return `${moment(mTsFrom).tz(timezone).format('DD.MM.YYYY HH:mm')} - ${moment(mTsTo).tz(timezone).format('HH:mm')}`
  }
})
Vue.filter('formatDateUntilTo', function(_:any, tsTo:number, timezone:string) {
    if (tsTo > 0) {
        const mTsTo = moment.unix(tsTo);
        return `${moment(mTsTo).tz(timezone).format('DD.MM.YYYY HH:mm')}`
    }
})
Vue.filter('formatPrice', function(value:any) {
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
});
Vue.prototype.$photoUrl = function(p?: {host: string, path: string, scheme: string}, size: number = 100) {
  if (!p) {
    return ''; //Todo set default image
  }
  return [p.scheme,p.host,`/resize/${size}x-/model/`,p.path].join('');
};
Vue.filter('photoUrl', Vue.prototype.$photoUrl);

Vue.prototype.$utils = Util;

let VueCookie = require('vue-cookie');
Vue.use(VueCookie);

const options = { lodash: lodash }
Vue.use(VueLodash, options)

console.groupCollapsed("Add component")
let requireComponent: any = {}

requireComponent = require.context(
    // Относительный путь до каталога компонентов
    './components',
    // Обрабатывать или нет подкаталоги
    true,
    // Регулярное выражение для определения файлов базовых компонентов
    /[\w\d_\-.]+\.(vue|js|ts)$/
)
requireComponent.keys().forEach((fileName:any) => {
    // Получение конфигурации компонента
    const componentConfig = requireComponent(fileName)

    let paths = fileName.split('/');
    const componentFileName: any = paths.splice(1).join('_')
    const componentName = upperFirst(camelCase(componentFileName.replace(/\.\w+$/, '')))
    console.info('Add component ' + componentName + ' from path ' + fileName)

    Vue.component(componentName, componentConfig.default || componentConfig)
})

requireComponent = require.context(
    // Относительный путь до каталога компонентов
    './modules',
    // Обрабатывать или нет подкаталоги
    true,
    // Регулярное выражение для определения файлов базовых компонентов
    /[\w\d_\-.]+\.component\.(vue|js|ts)$/
)
requireComponent.keys().forEach((fileName:any) => {
    // Получение конфигурации компонента
    const componentConfig = requireComponent(fileName)

    let paths = fileName.split('/');
    paths.slice(-2, 0)
    const componentFileName: any = paths.pop()
    const moduleName = camelCase(paths.pop())
    const componentName = upperFirst(camelCase(moduleName + '_' + componentFileName.replace(/\.component\.(vue|js|ts)$/, '')))
    console.info('Add component ' + componentName + ' from path ' + fileName, moduleName)

    Vue.component(componentName, componentConfig.default || componentConfig)
})
console.groupEnd()


new Vue({
    beforeCreate: function() {
      this.$store.commit('user/InitializeStore');
    },
    created: function () {
        let self = this
        console.info('Init application in mode', self)

      //TODO сделал пока костыль
      for (let store of ["user", "dashboard", "navigation", "main"]) {
        try {
            this.$store.dispatch(store + '/ClientInit', this)
        } catch (e) {
          console.log(e)
        }
      }
    },

    // vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

