import api from '@/plugins/api'

export default {
    namespaced: true,
    state: {
        page: 0,
        perPage: 20,
        totalCount: 0,
        loaded: false,
        locations: [],
        equipment: [],
        rooms: [],
        roomInfo: [],
        roomPrice: [],
        freeRoomTime: [],
        peerList: [],
        bookedRooms: [],
        datePeriod: {}
    },
    getters: {
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page;
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage;
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount;
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded;
        },
        locations: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.locations;
        },
        equipment: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.equipment;
        },
        rooms: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.rooms;
        },
        roomInfo: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.roomInfo;
        },
        roomPrice: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.roomPrice;
        },
        freeRoomTime: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.freeRoomTime;
        },
        peerList: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.peerList;
        },
        bookedRooms: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.bookedRooms;
        },
        datePeriod: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.datePeriod;
        }
    },
    mutations: {
        UpdatePage (state:any, page:any) {
            state.page = page;
        },
        UpdatePerPage (state:any, perPage:any) {
            state.perPage = perPage;
        },
        UpdateTotalCount (state:any, totalCount:any) {
            state.totalCount = totalCount;
        },
        UpdateLoaded (state:any, loaded:any) {
            state.loaded = loaded;
        },
        UpdateLocations (state:any, locations:any) {
            state.locations = locations;
        },
        UpdateEquipment (state:any, equipment:any) {
            state.equipment = equipment;
        },
        UpdateRooms (state:any, rooms:any) {
            state.rooms = rooms;
        },
        UpdateRoomInfo (state:any, roomInfo:any) {
            state.roomInfo = roomInfo;
        },
        UpdateRoomPrice (state:any, roomPrice:any) {
            state.roomPrice = roomPrice;
        },
        UpdateFreeRoomTime(state:any, freeRoomTime:any) {
            state.freeRoomTime = freeRoomTime;
        },
        UpdatePeerList(state:any, peerList:any) {
            peerList.unshift({ fio: 'all', email: 'all' });
            state.peerList = peerList;
        },
        UpdateBookedRooms(state:any, bookedRooms:any) {
            state.bookedRooms = bookedRooms;
        },
        SelectedDatePeriod(state:any, datePeriod:any) {
            state.datePeriod = datePeriod;
        }
    },
    actions: {
        LocationList ({commit}:any, data:any) {
            commit('UpdateLoaded', false);
            return new Promise((resolve, reject) => {
                api.get('/v2/locations', {
                    'fields': ['id', 'name'].join(','),
                    'coworking_ids': [data.coworking].join(','),
                }).then(responce => {
                    resolve(responce.data);
                    commit('UpdateLocations', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        EquipmentList ({commit}:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/directories/equipment', {
                'resource_type': 'meeting_room',
            })
                .then(responce => {
                    commit('UpdateEquipment', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                console.error('reason', reason)
            })
        },
        LocationInfo ({commit}:any, id: any) {
            commit('UpdateLoaded', false);
            return new Promise(resolve => {
                api.get('/v2/locations/' + id, { expand: 'policy,city' })
                    .then(responce => {
                        resolve(responce.data);
                        commit('UpdateLocations', responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        RoomsInit: ({commit}:any, data: any) => {
            commit('UpdateLoaded', false);
            return new Promise(resolve => {
                api.get('/v2/meeting-rooms', {
                    // sort: window.__env.roomsOrderBy ? window.__env.roomsOrderBy : 'cost',
                    sort: data.sort,
                    city_id: data.citi_id,
                    coworking_ids: [data.coworking].join(','),
                    location_ids: data.location_ids,
                    equipment: data.equipment.join(','),
                    seats: data.seats,
                    show_hidden: '0',
                    expand: data.expand,
                    page: data.page,
                    'per-page': data['per-page']
                })
                    .then(responce => {
                        resolve(responce.data);
                        commit('UpdateRooms', responce.data);
                        commit('UpdatePage', responce.headers['x-pagination-current-page']);
                        commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                        commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        GetRoomInfo: ({commit}:any, id:any) => {
            commit('UpdateLoaded', false);
            return new Promise(resolve => {
                api.get('/v2/meeting-rooms/' + id, {expand: 'coworking,location'})
                    .then(responce => {
                        resolve(responce.data);
                        commit('UpdateRoomInfo', responce.data);
                        commit('UpdateLoaded', true);
                    }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        GetRoomPrice: ({commit}:any, data: any) => {
            commit('UpdateLoaded', false);
            api.get('/v2/price/meeting-rooms', data)
                .then(responce => {
                    commit('UpdateRoomPrice', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                console.error('reason', reason)
            })
        },
        GetFreeTime: ({commit}:any, data: any) => {
            commit('UpdateLoaded', false);
            api.get('/v2/meeting-rooms/booked', {
                ids: data.ids,
                from: data.from,
            })
                .then(responce => {
                    commit('UpdateFreeRoomTime', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                console.error('reason', reason)
            })
        },
        GetPeerList: ({commit}:any) => {
            commit('UpdateLoaded', false);
            const staffList = [];
            api.get('/v2/companies', {
                'fields': ['staff'].join(','),
                expand: 'staff',
            })
                .then(responce => {
                    for (const i of responce.data) {
                        i.staff.reduce((reducer,el) => {
                            if (!reducer.find(l => l.id === el.id)) {
                                reducer.push(el)
                            }
                            if (reducer.length === 0 ) reducer.push(el)
                            return reducer
                        }, staffList)
                    }
                    commit('UpdatePeerList', staffList);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                console.error('reason', reason)
            })
        },
        // GetRoomsID: ({ commit }: any, data: any) {
        //     let body = Object.assign({sort: 'cost', coworking_ids: '1', show_hidden: '1'}, data);
        //     api.get('/v2/meeting-rooms', { body }).then(responce => { let ids = [...responce.data]; ids.map(r => r.id).join(','); return ids; })
        // },
        // GetBookedRooms ({ commit, state }: any, data: any) {
        //     commit('UpdateLoaded', false);
        //     let temp = {
        //         booked: [] as  any,
        //         rooms: state.rooms,
        //     };
        //     api.get('/v2/meeting-rooms', Object.assign({ sort: 'cost', show_hidden: '0', }, data.filter))
        //         .then(responce => {
        //             commit('UpdateRooms', responce.data);
        //             let ids = [...responce.data];
        //             api.get('/v2/meeting-rooms/booked', {
        //                 ids: ids.map(r => r.id).join(','),
        //                 from: data.timestamp,
        //             })
        //                 .then(responce => {
        //                     let abc = responce.data.map(item => ({
        //                         ...item,
        //                         ts_start: (item['time_offset'] && item.ts_start) ? item.ts_start + item['time_offset'] : item.ts_start,
        //                         ts_end: (item['time_offset'] && item.ts_end) ? item.ts_end + item['time_offset'] : item.ts_end,
        //                     }));
        //                 for (const item of abc) {
        //                     if (!temp.booked[item['resource_id']]) {
        //                         temp.booked[item['resource_id']] = [];
        //                     }
        //                     temp.booked[item['resource_id']].push(item);
        //                 }
        //                 temp.rooms = ids;
        //                 for (const item of temp.rooms) {
        //                     item.booked = temp.booked[item.id];
        //                 }
        //                 commit('UpdateBookedRooms', temp.booked);
        //                 commit('UpdateRooms', temp.rooms);
        //                 commit('UpdateLoaded', true);
        //             })
        //         })
        // },
        // CompaniesInit: ({commit}:any) => {
        //     commit('UpdateLoaded', false);
        //     api.get('/v2/companies', {})
        //         .then(responce => {
        //             commit('INIT_COMPANIES', responce.data);
        //             commit('UpdateLoaded', true);
        //         }).catch(reason => {
        //             console.error('reason', reason)
        //     })
        // }
    }
}
