import api from "@/plugins/api";

export default {
    namespaced: true,
    state: {
        list: {},
    },
    getters: {
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            // console.log('get list')
            // return state.identity
            return state.list
        },
    },
    mutations: {
        UpdateList (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.list = data
        }
    },
    actions: {
        GetData ({commit, state}:{commit:any, state:any}, data:any) {
            return new Promise((resolve, reject) => {
                api.get('/v2/users/check?expand=main_spaces', data)
                    .then(responce => {
                        api.get('/v2/locations', {
                            'ids_filter': responce.data.location_ids.join(','),
                            'visible': 1,
                            'page': 1,
                            'per-page': 1000,
                            'expand': ['city'].join(','),
                            'fields': ['id','name'].join(',')
                        }).then(responce => {
                            resolve(responce.data);
                            commit('UpdateList', responce.data);
                        })
                    })
            })
        },
    }
}

