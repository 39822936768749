import Vue from 'vue'
import VueRouter from 'vue-router'
// import camelCase from 'lodash-es/camelCase'
import Home from '@/views/Home.vue'
import store from '@/store' // your vuex store

Vue.use(VueRouter)


let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        link: false,
        meta: {
            layout: 'lk',
            weight: 0,
            title: 'Главная',
            icon: 'mdi-view-dashboard'
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        link: false,
        meta: {
            layout: 'lk',
            weight: 1000,
            title: 'О проекте',
            icon: 'mdi-help-circle'
        }
    },
    {
        path: '*',
        name: 'Error',
        // component: () => import('@/pages/Error') (Optional)
        meta: {
            layout: 'error' // name of the layout
        }
    }
]


const requireComponent = require.context(
    // Относительный путь до каталога компонентов
    '../modules',
    // Обрабатывать или нет подкаталоги
    true,
    // Регулярное выражение для определения файлов базовых компонентов
    /[\w\d\_\-\.]+\.router\.(ts|js)$/
)

for (let route of requireComponent.keys()) {
    let routeConfig = requireComponent(route)
    routes = routes.concat(routeConfig.default)
}

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: routes,
})

// Специальный фикс для прокрутки после перехода по ссылки к блоку по якорю id = #id из ссылки
router.afterEach( (to, from) => {
    setTimeout(function () {
        if (to.hash && document.querySelector(to.hash)) {
            let block = document.querySelector(to.hash)
            console.log(block)
            if (block) {
                console.log('scroll')
                block.scrollIntoView()
            }
        }
    }, 2000)
})

router.beforeEach(async (to, from, next) => {
    const ps = null;

    if (((to.name == 'events' || to.name == 'events_view') && !window.__env.widget_events) ||
        ((to.name == 'offers' || to.name == 'offers_view') && !window.__env.widget_offers)) {
        next({name: 'login'})
    }
    else if ((to.name == 'bookings' && !window.__env.tab_bookings) ||
        (to.name == 'rooms' && !window.__env.tab_meeting_rooms) ||
        (to.name == 'booking_workplace' && !window.__env.tab_booking_workplace)) {
        next({name: 'home'})
    }
    else if (store.getters['user/isAuthenticated'] && to.name != 'login') {
        next()
        return
    }
    else if (store.getters['user/isAuthenticated'] && to.name == 'login') {
        next({name: 'home'})
        return
    }
    else if (store.getters['user/isAuthenticated']) {
        next()
        return
    }
    else if (to.name == 'signup') {
        next()
        return
    }
    else if (to.name == 'restore') {
        next()
        return
    }
    else if (to.name == 'checkout') {
        next()
        return
    }
    else if (to.name == 'password-recovery') {
        next()
        return
    }
    else if (to.name == 'jwt-login') {
        next()
        return
    }
    else if (to.name != 'login') {
        if(!store.getters['user/isAuthenticated']) {
            next({name: 'login'})
        } else {
            next()
        }
    }
    else {
        next()
    }
})

export default router
