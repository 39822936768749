<template>
  <!--begin::Header-->
  <div id="kt_header" class="header flex-column header-fixed">
    <!--begin::Top-->
    <div class="header-top h-auto h-xl-70px bg-primary">

      <!--begin::Container-->
      <div class="container py-xl-0">
        <!--begin::Navbar-->
        <b-navbar toggleable="xl" type="dark" class="w-100 p-0">
          <router-link to="/" class="mr-10">
            <img alt="Logo" :src="logo.url" class="h-35px max-h-35px" />
          </router-link>
          <b-navbar-toggle target="nav-collapse" class="my-4"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav class="h-auto h-xl-100">
            <!--begin::Left-->
            <div class="d-flex align-items-center align-self-xl-end mt-3 mt-xl-0 mr-3">
              <!--begin::Tab Navs(for desktop mode)-->
              <ul class="header-tabs nav flex-column flex-xl-row font-size-lg pl-0 pl-xl-5 w-100 w-xl-auto flex-nowrap" role="tablist">
                <!--begin::Item-->
                <router-link
                    v-for="link in navigationLinks"
                    :to="link.to" :key="link.to"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <li v-if="isActiveLink(link)" class="nav-item" :class="[menuLinks.length > 0 && 'with_menu']">
                    <a :href="href" @click="navigate" class="nav-link py-3 py-xl-4 px-4 px-xl-6 text-nowrap"
                       :class="[
                         isActive && 'active',
                         isExactActive && 'router-link-exact-active',
                         isActive && menuLinks.length <= 0 && 'background'
                     ]">{{ $t('menu.' + link.title) }}</a>
                  </li>
                </router-link>
                <!--end::Item-->
              </ul>
              <!--begin::Tab Navs-->
            </div>
            <!--end::Left-->

            <!--begin::Topbar-->
            <div class="topbar primary ml-auto position-relative justify-content-start justify-content-xl-end px-0 px-lg-5">
              <div class="topbar-item">
                <div class="btn btn-icon btn-hover-transparent-white btn-lg mr-3">
                  <b-dropdown variant="link" toggle-class="text-decoration-none text-white">
                    <template #button-content>
                      <font-awesome-icon icon="users" class="text-white icon-md"/>
                    </template>
                    <small class="text-muted px-4">{{ $t('coworking.Select_Title') }}</small>
                    <div class="d-flex align-items-center px-4 py-2">
                      <select class="form-control" style="width: 200px;" v-model="coworking">
                        <option v-for="coworking in coworkingList" :value="coworking.id">{{ coworking.name }}</option>
                      </select>
                    </div>
                  </b-dropdown>
                </div>
              </div>
              <template v-if="integrations && integrations.indexOf('integration_visitors') > -1">
                <div class="topbar-item">
                  <div class="btn btn-icon btn-hover-transparent-white btn-lg mr-3">
                    <router-link
                        to="/attendance"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <a :href="href" @click="navigate" class="text-white" :class="[
                       isActive && 'active',
                       isExactActive && 'router-link-exact-active',
                       isActive
                   ]">
                        <font-awesome-icon icon="chart-bar" class="icon-md" />
                      </a>
                    </router-link>
                  </div>
                </div>
              </template>
              <div class="topbar-item">
                <div class="btn btn-icon btn-hover-transparent-white btn-lg mr-3">
                  <router-link
                      to="/invoice"
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                  >
                    <a :href="href" @click="navigate" class="text-white" :class="[
                       isActive && 'active',
                       isExactActive && 'router-link-exact-active',
                       isActive
                   ]">
                      <font-awesome-icon icon="file-invoice-dollar" class="icon-md" />
                    </a>
                  </router-link>
                </div>
              </div>
              <div class="topbar-item">
                <div class="btn btn-icon btn-hover-transparent-white btn-lg mr-3">
                  <b-dropdown variant="link" toggle-class="text-decoration-none text-white">
                    <template #button-content>
                      <font-awesome-icon icon="cogs" class="text-white icon-md"/>
                    </template>
                    <b-dropdown-item @click="go('/employees')">{{ $t('menu.staff') }}</b-dropdown-item>
                    <b-dropdown-item @click="go('/notification-settings')">{{ $t('menu.notifications') }}</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="topbar-item">
                <div class="btn btn-icon btn-hover-transparent-white btn-lg mr-3">
                  <b-dropdown variant="link" toggle-class="text-decoration-none text-white">
                    <template #button-content>
                      <font-awesome-icon icon="globe-americas" class="text-white icon-md"/>
                    </template>
                    <small class="text-muted px-4">{{ $t('menu.language') }}</small>
                    <b-dropdown-item @click="switchLocale('en')">English</b-dropdown-item>
                    <b-dropdown-item @click="switchLocale('ru')">Русский</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="topbar-item">
                <div class="btn btn-icon btn-hover-transparent-white btn-lg" @click="toggleQuickUser" v-if="isAuthenticated">
                  <font-awesome-icon icon="user-circle" class="icon-md"/>
                </div>
              </div>
              <!--          &lt;!&ndash;begin::User&ndash;&gt;-->
              <!--          <div class="topbar-item" @click="toggleQuickUser" v-if="isAuthenticated">-->
              <!--            <div class="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">-->
              <!--              <div class="d-flex flex-column text-right pr-sm-3">-->
              <!--                <span class="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">{{identity.phone}}</span>-->
              <!--                <span class="text-white font-weight-bolder font-size-sm d-none d-sm-inline">{{identity.fio}}</span>-->
              <!--              </div>-->
              <!--              <span class="symbol symbol-35">-->
              <!--                <span class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">S</span>-->
              <!--              </span>-->
              <!--            </div>-->
              <!--          </div>-->
              <!--          &lt;!&ndash;end::User&ndash;&gt;-->
            </div>
            <!--end::Topbar-->

          </b-collapse>
        </b-navbar>
        <!--end::Navbar-->
        <!--begin::Left-->
<!--&lt;!&ndash;          &lt;!&ndash;begin::User&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="topbar-item" @click="toggleQuickUser" v-if="isAuthenticated">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="d-flex flex-column text-right pr-sm-3">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">{{identity.phone}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="text-white font-weight-bolder font-size-sm d-none d-sm-inline">{{identity.fio}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="symbol symbol-35">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">S</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          &lt;!&ndash;end::User&ndash;&gt;&ndash;&gt;-->
<!--        </div>-->
        <!--end::Topbar-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Top-->
    <div class="header-bottom" v-if="menuLinks.length > 0">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Header Menu Wrapper-->
        <div class="header-navs header-navs-left" id="kt_header_navs">
          <!--begin::Tab Content-->
          <div class="tab-content">
            <!--begin::Tab Pane-->
            <div class="tab-pane p-lg-0 show active" id="kt_header_tab_1">
              <!--begin::Menu-->
              <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                <!--begin::Nav-->
                <ul class="menu-nav">
                  <router-link
                      v-for="item in menuLinks"
                      :to="item.path" :key="item.to"
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                  >
                    <li v-if="isTabAvailable(item.name)"
                        class="menu-item" :class="[isExactActive && 'menu-item-here']" aria-haspopup="true">
                      <a :href="item.path" @click="navigate" class="menu-link">
                        <span class="menu-text primary--text">{{ $t('menu.' + item.title) }}</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
                <!--end::Nav-->
              </div>
              <!--end::Menu-->
            </div>
            <!--begin::Tab Pane-->
          </div>
          <!--end::Tab Content-->
        </div>
        <!--end::Header Menu Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--begin::Set Coworking Modal-->
    <SetCoworkingModal
        :showCoworkingModal="showCoworkingModal"/>
    <!--end::Set Coworking Modal-->
  </div>
  <!--end::Header-->
</template>

<style>
.menu {
  padding: 0px;
  margin: 0px;
}
.container {
  padding: 0px;
}
.b-dropdown ul,
.b-dropdown ol {
  padding-left: 0px !important;
}
</style>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import api from "@/plugins/api";
let cookie = require('vue-cookie');

import SetCoworkingModal from "./SetCoworkingModal";

export default {
  components: {
    SetCoworkingModal
  },
  computed: {
    ...mapState({
      showMini: state => state.navigation.mini,
      navigationLinks: state => state.navigation.links
    }),
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      token: 'user/token',
      identity: 'user/identity',
      logo: 'main/logo',
      integrations: 'main/integrations',
      coworkingList: 'spaces/coworking',
    }),
  },
  data: () => {
    return {
      menuLinks: [],
      showCoworkingModal: false,
      coworking: localStorage.getItem('coworkingId_' + localStorage.getItem('currentUserId')) || ''
    }
  },
  mounted() {
    this.fetch();
    let parentMenuDefault = []
    if (this.$route.meta.parent && this.$router.match(this.$route.meta.parent)) {
      if (this.$router.match(this.$route.meta.parent).meta) {
        parentMenuDefault = this.$router.match(this.$route.meta.parent).meta.menu || []
      }
    }
    this.menuLinks = this.$route.meta.menu || parentMenuDefault
  },
  methods: {
    ...mapActions('quick_user', [
      'toggleQuickUser'
    ]),
    isActiveLink(link) {
      if (link.name === 'passes') {
        return !!(this.integrations && this.integrations.indexOf('integration_otp') > -1);
      } else {
        return true;
      }
    },
    isTabAvailable(name) {
      if (name === 'bookings' && !window.__env.tab_bookings) {
        return false;
      } else if (name === 'rooms' && !window.__env.tab_meeting_rooms) {
        return false;
      } else if (name === 'booking_workplace' && !window.__env.tab_booking_workplace) {
        return false;
      }
      return true;
    },
    go(link) {
      this.$router.push(link)
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        cookie.set("locale", locale, {expires: '1Y', path: '/'})
        document.location.reload()
      }
    },
    async logout() {
      console.log('Logout')
      try {
        cookie.delete('auth_key');
        this.$store.commit('user/UpdateIdentity', {});
        this.$store.commit('user/CurrentToken', null);

        this.$router.push('/login')
      } catch (e) {
        this.error = e.message
      }
    },
    fetch() {
      this.$store.dispatch('spaces/GetCoworking', {});
      let key = 'coworkingId_' + localStorage.getItem('currentUserId');
      let coworkingId = localStorage.getItem(key) || '';
      if (coworkingId) {
        this.$store.dispatch('spaces/GetData', { coworking: coworkingId })
      } else {
        this.showCoworkingModal = true;
        this.$store.dispatch('spaces/GetData', {})
      }
    }
  },
  watch: {
    $route(to, from) {
      let parentMenuDefault = []
      if (this.$route.meta.parent && this.$router.match(this.$route.meta.parent)) {
        if (this.$router.match(this.$route.meta.parent).meta) {
          parentMenuDefault = this.$router.match(this.$route.meta.parent).meta.menu || []
        }
      }
      this.menuLinks = this.$route.meta.menu || parentMenuDefault
    },
    coworking(val) {
      localStorage.setItem('coworkingId_' + this.identity.id, val);
      document.location.reload();
    }
  },
}
</script>

<style lang="scss">
  .base-logo {
    width: 150px;
    height: 150px;
  }
</style>
