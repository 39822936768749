<template>
  <b-modal v-model="passRequestModal" size="md"
           id="pass_order"
           @ok="sendPassRequest"
           @hidden="hidePassRequestModal"
           :title="passRequestStatus == null ? $t('ordered_passes_modal.title') : null"
           :ok-title="$t('ordered_passes_modal.submit')"
           :cancel-title="$t('ordered_passes_modal.cancel')">
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button v-if="passRequestStatus == null"
                size="md" @click="hidePassRequestModal">{{ $t('ordered_passes_modal.cancel') }}</b-button>
      <b-button v-if="passRequestStatus == null"
                size="md" variant="success" @click="sendPassRequest"
                :disabled="passRequestProcessing || !isValid">{{ $t('ordered_passes_modal.submit') }}</b-button>
    </template>
    <div v-if="passRequestStatus == null">
      <b-form>
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.user_type') }}: <span class="text-danger">*</span></label>
          <select v-model="user_type"
                  @change="onChangeUserType($event)"
                  class="form-control">
            <option v-for="type in userTypeList" :value="type.id">{{ type.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.company_type') }}: <span class="text-danger">*</span></label>
          <select v-model="company_type" class="form-control">
            <option v-for="type in companyTypeList" :value="type.id">{{ type.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.location_id') }}: <span class="text-danger">*</span></label>
          <select v-model="location_id" class="form-control">
            <option v-for="type in locations" :value="type.id">{{ type.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.last_name') }}: <span class="text-danger">*</span></label>
          <b-form-input
              class="form-control"
              :placeholder="$t('ordered_passes_modal.last_name_placeholder')"
              v-model="last_name"
              :disabled="passRequestProcessing"
          ></b-form-input>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.first_name') }}: <span class="text-danger">*</span></label>
            <b-form-input
                class="form-control"
                :placeholder="$t('ordered_passes_modal.first_name_placeholder')"
                v-model="first_name"
                :disabled="passRequestProcessing"
            ></b-form-input>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.father_name') }}: <span class="text-danger">*</span></label>
            <b-form-input
                class="form-control"
                :placeholder="$t('ordered_passes_modal.father_name_placeholder')"
                v-model="father_name"
                :disabled="passRequestProcessing || withoutFatherName"
            ></b-form-input>
            <!--  <b-form-checkbox size="lg" v-model="withoutFatherName" @change="onChangeFatherName" class="mt-2">{{ $t('quickUser.guestCard.WithoutFatherName') }}</b-form-checkbox>-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.phone') }}: <span class="text-danger">*</span></label>
<!--            <input-->
<!--                class="form-control"-->
<!--                :placeholder="$t('ordered_passes_modal.phone_placeholder')"-->
<!--                v-model="phone"-->
<!--                v-mask="'+# ### ###-##-##'"-->
<!--                :disabled="passRequestProcessing"-->
<!--            />-->
            <vue-tel-input
                ref="phoneNumber"
                class="form-control"
                v-model="phone"
                :disabled="passRequestProcessing"
                v-bind="bindProps"
                :placeholder="$t('ordered_passes_modal.phone_placeholder')"
                @focus="onFocus"
                v-on:validate="checkPhoneValid"></vue-tel-input>
            <span v-if="phone && !isPhoneValid" class="text-danger">{{ $t('ordered_passes_modal.phone_warning') }}</span>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.email') }}: <span class="text-danger">*</span></label>
            <b-form-input
                class="form-control"
                :placeholder="$t('ordered_passes_modal.email_placeholder')"
                v-model="email"
                :disabled="passRequestProcessing"
            ></b-form-input>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.passport_number') }}: <span class="text-danger">*</span></label>
          <input
              class="form-control"
              :placeholder="$t('ordered_passes_modal.passport_number_placeholder')"
              v-model="passport_number"
              v-mask="'NNNN NNNNNN?NNNNNN'"
              :disabled="passRequestProcessing"
          />
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.passport_issued') }}: <span class="text-danger">*</span></label>
            <b-form-input
                class="form-control"
                :placeholder="$t('ordered_passes_modal.passport_issued_placeholder')"
                v-model="passport_issued"
                :disabled="passRequestProcessing"
            ></b-form-input>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ $t('ordered_passes_modal.passport_date') }}: <span class="text-danger">*</span></label>
            <date-picker v-model="passport_date"
                         :placeholder="$t('ordered_passes_modal.passport_date_placeholder')"
                         valueType="format" format="DD.MM.YYYY"
                         :disabled-date="(date) => setAvailableDatesTo(date)"
                         :disabled="passRequestProcessing"
                         :lang="$t('data-piker')"></date-picker>
          </div>
        </div>
<!--        <div class="form-group">-->
<!--          <label>{{ $t('ordered_passes_modal.type') }}: <span class="text-danger">*</span></label>-->
<!--          <select v-model="type"-->
<!--                  class="form-control">-->
<!--            <option v-for="type in passTypeList" :value="type.id">{{ type.name }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.ts_start') }}: <span class="text-danger">*</span></label><br>
          <date-picker v-model="ts_start"
                       :placeholder="$t('ordered_passes_modal.ts_start_placeholder')"
                       type="datetime" format="DD.MM.YYYY HH:mm"
                       :disabled-date="(date) => setAvailableDatesFrom(date)"
                       :disabled="passRequestProcessing"
                       :lang="$t('data-piker')"></date-picker>
        </div>
<!--        <div class="form-group">-->
<!--          <label>{{ $t('ordered_passes_modal.target_id') }}: <span class="text-danger">*</span></label>-->
<!--          <select v-model="target_id"-->
<!--                  class="form-control">-->
<!--            <option v-for="type in targets" :value="type.id">{{ type.name }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="form-group">
          <label>{{ $t('ordered_passes_modal.comment') }}:</label>
          <b-form-textarea
              class="form-control"
              :placeholder="$t('ordered_passes_modal.comment_placeholder')"
              v-model="comment"
              :disabled="passRequestProcessing"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </div>
      </b-form>
    </div>
    <div v-if="passRequestStatus == false" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.guestCard.RequestErrorSending') }}</h3>
      <!--        <p>{{ $t('quickUser.guestCard.ErrorMassage') }}</p>-->
    </div>
    <div v-if="passRequestStatus == true" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="check" class="text-success icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.guestCard.RequestSuccessfully') }}</h3>
      <!--        <p>{{ $t('quickUser.guestCard.SuccessMessage') }}</p>-->
    </div>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import moment from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import cookie from "vue-cookie";

export default {
  name: "OrderPassModal",
  components: {
    DatePicker
  },
  props: {
    showPassRequestModal: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState({
      identity: state => state.user.identity,
    }),
    ...mapGetters({
      coworkingById: 'spaces/coworkingById',
      identity: 'user/identity',
      targets: 'dashboard/targets',
      locations: 'tariffServices/locations',
    }),
    isValid() {
      if (this.user_type !== null && this.company_type !== null && this.location_id !== null && this.first_name.length > 1 && this.last_name.length > 1 && this.father_name.length > 1 &&
          (this.phone && this.isPhoneValid) && this.validateEmail(this.email) &&
          (this.passport_number && this.passport_number.length >= 10) && this.passport_issued && this.passport_date &&
          this.ts_start) return true;
      // this.type !== null && this.target_id !== null
      // else if (this.first_name.length > 1 && this.last_name.length > 1 && this.withoutFatherName) return true;
      else return false;
    }
  },
  mounted() {
    if (this.coworkingById && this.coworkingById.id) this.filter.coworking = this.coworkingById.id;
    this.$store.dispatch('tariffServices/GetData', this.filter);
  },
  data() {
    return {
      filter: {
        location_id: '',
        page: 1,
      },
      // passes_list: [],
      hasRotated: false,
      passRequestModal: false,
      passRequestStatus: null,
      passRequestProcessing: false,
      bindProps: {
        mode: "international",
        // validCharactersOnly: true,
        // defaultCountry: "RU",
        preferredCountries: ["RU", "KZ", "IL"],
        inputOptions: {
          showDialCode: true,
          autofocus: true
        }
      },
      isPhoneValid: false,
      userTypeList: [
        { id: 1, name: cookie.get('locale') === 'ru' ? 'На себя' : 'To myself' },
        { id: 2, name: cookie.get('locale') === 'ru' ? 'На гостя' : 'Per guest' }
      ],
      companyTypeList: [],
      user_type: null,
      company_type: null,
      location_id: null,
      first_name: '',
      last_name: '',
      father_name: '',
      withoutFatherName: false,
      phone: null,
      email: null,
      passport_number: null,
      passport_issued: null,
      passport_date: null,
      // passTypeList: [
      //   { id: 1, name: cookie.get('locale') === 'ru' ? 'Разовый' : 'One-time' },
      //   { id: 2, name: cookie.get('locale') === 'ru' ? 'Временный' : 'Temporary' }
      // ],
      // type: null, // Тип пропуска
      ts_start: null, // moment().format("DD.MM.YYYY HH:mm")
      // target_id: null,
      comment: null,
      adminInCompanies: []
    }
  },
  methods: {
    onFocus() {
      const input = this.$refs.phoneNumber.$el.querySelector('input');
      const end = input.value.length;
      this.$nextTick(() => {
        input.setSelectionRange(end, end);
        input.focus();
      })
    },
    onAdminSelected () {
      this.$store.dispatch('user/GetCompanies').then(res => {
        let userCompanies = res;
        this.adminInCompanies.forEach(item => {
          userCompanies.forEach(i => {
            if (item === i.id) {
              this.companyTypeList.push(i);
            }
          })
        })
      });
    },
    checkPhoneValid(phoneObject) {
      this.isPhoneValid = phoneObject.isValid;
    },
    setAvailableDatesTo(date) {
      let today = new Date();
      let yesterday = new Date();
      return date > yesterday.setDate(today.getDate());
    },
    setAvailableDatesFrom(date) {
      let today = new Date();
      let yesterday = new Date();
      return date < yesterday.setDate(today.getDate() - 1);
    },
    onChangeUserType(event) {
      if (!this.companyTypeList.find(x => x.id === this.identity.id)) {
        this.companyTypeList.push({ id: this.identity.id, name: this.identity.fio });
      }
      if (event.target.value === '1') {
        this.company_type = this.identity.id;
        this.first_name = this.identity.first_name;
        this.last_name = this.identity.last_name;
        this.father_name = this.identity.father_name;
        this.phone = this.identity.phone;
        this.email = this.identity.email;
      } else {
        this.company_type = null;
        this.first_name = '';
        this.last_name = '';
        this.father_name = '';
        this.phone = '';
        this.email = '';
      }
    },
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    sendPassRequest() {
      let self = this;
      this.passRequestModal = true;
      this.passRequestProcessing = true;
      this.passRequestStatus = null;
      let data = {
        company_id: self.company_type === this.identity.id ? null : self.company_type,
        first_name: self.first_name,
        last_name: self.last_name,
        father_name: self.father_name === '' ? null : self.father_name,
        phone: self.phone,
        email: self.email,
        passport_number: self.passport_number,
        passport_issued: self.passport_issued,
        passport_date: moment(self.passport_date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
        // type: self.type,
        type: 1,
        date_start: moment(self.ts_start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
        date_end: moment(self.ts_start, 'YYYY-MM-DD HH:mm').add('days', 1).format('YYYY-MM-DD HH:mm'), // + 24 hours
        // target_id: self.target_id,
        comment: self.comment,
        location_id: self.location_id,
      };

      this.$store.dispatch('dashboard/PostNewPass', data)
          .then(() => {
            self.user_type = null;
            self.company_type = null;
            self.first_name = '';
            self.last_name = '';
            self.father_name = '';
            self.phone = null;
            self.email = null;
            self.passport_number = null;
            self.passport_issued = null;
            self.passport_date = null;
            // self.type = null;
            self.ts_start = null;
            // self.target_id = null;
            self.location_id = null;
            self.comment = null;
            self.passRequestProcessing = false;
            self.passRequestModal = true;
            self.passRequestStatus = true;
            self.$store.dispatch('dashboard/PassesInit', self.coworkingById.id);
            this.$emit('updatePassRequestModal', true);
          })
          .catch(error => {
            let _error = []
            try {
              if (error.response.data.error && error.response.data.error.extra) {
                for (let i in error.response.data.error.extra) {
                  _error.push(error.response.data.error.extra[i][0])
                }
              }
            } catch (e) {}
            self.requestSupportError = _error.join('<br>')
            self.passRequestProcessing = false
            self.passRequestStatus = false
            self.passRequestModal = true
          });
    },
    hidePassRequestModal() {
      this.passRequestModal = false;
      this.passRequestStatus = null;
      this.passRequestProcessing = false;
      this.$emit('updatePassRequestModal', false);
    },
    onChangeFatherName() {
      this.father_name = '';
    }
  },
  watch: {
    showPassRequestModal(val) {
      this.passRequestModal = val;
      if (!this.hasRotated) {
        if (this.identity.company_ids) {
          this.identity.company_ids.forEach((item, idx, array) => {
            this.$store.dispatch('user/GetCompanyEmployees', {
              id: item,
              page: 1,
              perPage: 500,
            }).then(res => {
              res.forEach(i => {
                if (i.id === this.identity.id && i.role === 'administrator') {
                  this.adminInCompanies.push(item)
                }
              });
              if (idx === array.length - 1){
                this.onAdminSelected();
                this.hasRotated = true;
              }
            });
          });
        }
      }
    },
  }
}
</script>

<style lang="scss">
  .modal-header {
    border-bottom: none!important;
  }
  .modal-footer {
    border-top: none!important;
  }
  #pass_order {
    .modal-body {
      padding: 0 1.75rem 1.75rem!important;
    }
    .modal-title {
      font-weight: bold!important;
    }
    .form-group {
      margin-bottom: 0.75rem!important;
    }
    .mx-datepicker {
      width: 100%!important;
    }
  }
</style>
