import api from "@/plugins/api";

export default {
    namespaced: true,
    state: {
        coworking: [],
        coworkingById: {},
        list: {},
        countList: 0,
        info: {},
        guide: [],
        loaded: false
    },
    getters: {
        coworking: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.coworking
        },
        coworkingById: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.coworkingById
        },
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.list
        },
        countList: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.countList
        },
        info: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.info
        },
        guide: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.guide
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
    },
    mutations: {
        UpdateCoworking (state:any, data:any) {
            state.coworking = data
        },
        UpdateCoworkingById (state:any, data:any) {
            state.coworkingById = data
        },
        UpdateList (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.list = data
        },
        UpdateCountList (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.countList = data
        },
        UpdateInfo (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.info = data
        },
        GuideInfo (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.guide = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        }
    },
    actions: {
        GetCoworking({commit, state}:{commit:any, state:any}, data:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/coworkings', {
                'visible': 1,
                'page': 1,
                'per-page': 200,
                'expand': ['name'].join(',')
            }).then(responce => {
                commit('UpdateCoworking', responce.data);
                commit('UpdateLoaded', true);
            })
            return this
        },
        GetCoworkingById({commit, state}:{commit:any, state:any}, data:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/coworkings/' + data.id)
                .then(responce => {
                    commit('UpdateCoworkingById', responce.data);
                    commit('UpdateLoaded', true);
                })
            return this
        },
        GetData ({commit, state}:{commit:any, state:any}, data:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/users/check?expand=main_spaces', data)
                .then(responce => {
                    api.get('/v2/locations', {
                        'ids_filter': responce.data.location_ids.join(','),
                        'visible': 1,
                        'page': 1,
                        'per-page': 20,
                        'coworking_ids': [data.coworking].join(','),
                        // 'fields': ['id','name'].join(','),
                        'expand': ['tariffs','policy','cost','currency','meeting_rooms_count', 'phone', 'office_short_info', 'city'].join(',')
                        // 'expand': ['coworking','tariffs','floors','city','description_meeting_room','services','policy',
                        //     'documents','cost','currency','special_days','meeting_rooms_count'].join(',')
                    }).then(responce => {
                        commit('UpdateList', responce.data);
                        commit('UpdateCountList', responce.headers['x-pagination-total-count']);
                        commit('UpdateLoaded', true);
                    })
                })
            return this
        },
        GetInfo ({commit, state}:{commit:any, state:any}, id:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/locations/' + id, {
                'expand': ['tariffs','policy','cost','currency','meeting_rooms_count', 'phone', 'documents', 'office_short_info'].join(',')
            })
                .then(responce => {
                    commit('UpdateInfo', responce.data);
                    api.get('/v2/locations/' + id + '/office-guide', {
                        'type': ['wifi','printer','other','infrastructure','contact', 'driving_directions'].join(',')
                    })
                        .then(responce => {
                            commit('GuideInfo', responce.data);
                            commit('UpdateLoaded', true);
                        })
                })
            return this
        }
    }
}

