<template>
  <b-modal v-model="dialogRequestToGuestCardModal" size="md"
           @ok="sendRequestForGuestCard"
           @hidden="hidesGuestCardModal"
           :title="$t('quickUser.guestCard.PassForGuest')"
           :ok-title="$t('quickUser.guestCard.Submit')"
           :cancel-title="$t('quickUser.guestCard.Cancel')">
    <template #modal-footer="{ ok, cancel, hide }">
      <b-button v-if="dialogRequestToGuestCardStatus == null" size="sm" variant="success" @click="sendRequestForGuestCard"
                :disabled="dialogRequestToGuestCardProcessing || !isValid">{{ $t('quickUser.guestCard.Submit') }}</b-button>
      <b-button size="sm" variant="" @click="hidesGuestCardModal">{{ $t('quickUser.guestCard.Cancel') }}</b-button>
    </template>
    <div v-if="dialogRequestToGuestCardStatus == null">
      <b-form>
        <p>{{ $t('quickUser.guestCard.PassDescription') }}</p>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.LastName') }} <span class="text-danger">*</span></label>
          <b-form-input
              class="form-control"
              v-model="last_name"
              :placeholder="$t('quickUser.guestCard.EnterLastName')"
              :disabled="dialogRequestToGuestCardProcessing"
          ></b-form-input>
        </div>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.FirstName') }} <span class="text-danger">*</span></label>
          <b-form-input
              class="form-control"
              v-model="first_name"
              :placeholder="$t('quickUser.guestCard.EnterFirstName')"
              :disabled="dialogRequestToGuestCardProcessing"
          ></b-form-input>
        </div>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.MiddleName') }} <span class="text-danger">*</span></label>
          <b-form-input
              class="form-control"
              v-model="middle_name"
              :placeholder="$t('quickUser.guestCard.EnterMiddleName')"
              :disabled="dialogRequestToGuestCardProcessing || withoutMiddleName"
          ></b-form-input>
          <b-form-checkbox size="lg" v-model="withoutMiddleName" @change="onChangeMiddleName" class="mt-2">{{ $t('quickUser.guestCard.WithoutMiddleName') }}</b-form-checkbox>
        </div>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.Date') }} <span class="text-danger">*</span></label><br>
          <date-picker v-model="arrive_date"
                       valueType="format" format="DD.MM.YYYY"
                       :disabled-date="(date) => setAvailableDates(date)"
                       :disabled="dialogRequestToGuestCardProcessing"
                       :lang="$t('data-piker')"></date-picker>
        </div>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.Space') }} <span class="text-danger">*</span></label>
          <select v-model="location_id" class="form-control" :disabled="dialogRequestToGuestCardProcessing">
            <option v-for="type in spaceList" :value="type.id">{{ type.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t('quickUser.guestCard.AdditionalInfo') }}</label>
          <b-form-textarea
              class="form-control"
              v-model="additional_data"
              :placeholder="$t('quickUser.guestCard.EnterAdditionalInfo')"
              :disabled="dialogRequestToGuestCardProcessing"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </div>
      </b-form>
    </div>
    <div v-if="dialogRequestToGuestCardStatus == false" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.guestCard.RequestErrorSending') }}</h3>
      <p>{{ $t('quickUser.guestCard.ErrorMassage') }}</p>

      <p class="mt-5 text-danger">{{ requestSupportError }}</p>
    </div>
    <div v-if="dialogRequestToGuestCardStatus == true" class="d-flex flex-column align-items-center">
      <div class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="check" class="text-success icon-lg"/>
        </div>
      </div>
      <h3>{{ $t('quickUser.guestCard.RequestSuccessfully') }}</h3>
      <p>{{ $t('quickUser.guestCard.SuccessMessage') }}</p>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import api from "@/plugins/api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "GuestCardModal",
  components: {
    DatePicker
  },
  data() {
    return {
      requestSupportError: null,
      dialogRequestToGuestCardModal: false,
      dialogRequestToGuestCardStatus: null,
      dialogRequestToGuestCardProcessing: false,
      first_name: '',
      last_name: '',
      middle_name: '',
      withoutMiddleName: false,
      arrive_date: moment().format("DD.MM.YYYY"),
      location_id: null,
      additional_data: null
    }
  },
  props: {
    showGuestCardModal: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      identity: 'user/identity',
      spaceList: 'spaces/list',
    }),
    ...mapState({
      identity: state => state.user.identity,
    }),
    isValid() {
      if (this.first_name.length > 2 && this.last_name.length > 2 && this.middle_name.length > 2 && this.location_id && this.arrive_date) return true;
      else if (this.first_name.length > 2 && this.last_name.length > 2 && this.withoutMiddleName && this.location_id && this.arrive_date) return true;
      else return false;
    }
  },
  watch: {
    showGuestCardModal(val) {
      this.dialogRequestToGuestCardModal = val;
    }
  },
  methods: {
    setAvailableDates(date) {
      let today = new Date();
      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      return date < yesterday.setDate(today.getDate() - 1);
    },
    sendRequestForGuestCard() {
      let self = this;
      this.dialogRequestToGuestCardModal = true;
      this.dialogRequestToGuestCardProcessing = true;
      this.dialogRequestToGuestCardStatus = null;
      api.post('/v2/guests', {
        first_name: self.first_name,
        last_name: self.last_name,
        middle_name: self.middle_name === '' ? null : self.middle_name,
        arrive_date: moment(self.arrive_date, 'DD.MM.YYYY').format("YYYY-MM-DD"),
        location_id: self.location_id,
        additional_data: self.additional_data
      })
      .then(() => {
        self.first_name = '';
        self.last_name = '';
        self.middle_name = '';
        self.arrive_date = moment().format("DD.MM.YYYY");
        self.location_id = null;
        self.additional_data = null;
        self.dialogRequestToGuestCardProcessing = false
        self.dialogRequestToGuestCardModal = true
        self.dialogRequestToGuestCardStatus = true
      })
      .catch(error => {
        let _error = []
        try {
          if (error.response.data.error && error.response.data.error.extra) {
            for (let i in error.response.data.error.extra) {
              _error.push(error.response.data.error.extra[i])
            }
          }
        } catch (e) {}
        self.requestSupportError = _error.join('<br>')
        self.dialogRequestToGuestCardProcessing = false
        self.dialogRequestToGuestCardStatus = false
        self.dialogRequestToGuestCardModal = true
      });
    },
    hidesGuestCardModal() {
      this.requestSupportError = null;
      this.dialogRequestToGuestCardModal = false;
      this.dialogRequestToGuestCardStatus = null;
      this.dialogRequestToGuestCardProcessing = false;
      this.$emit('updateDialogRequestToGuestCardModal', false);
    },
    onChangeMiddleName() {
      this.middle_name = '';
    }
  }
}
</script>

<style scoped>

</style>
